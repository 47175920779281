import './loading.scss'

import React, { useEffect, useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import Seo from '@src/components/Seo'
import useTranslations from '@src/hooks/useTranslations'
import usePageContextData from '@src/utils/brickresolvers/usePageContextData'
import Account from '@src/components/account/Account'
import { getSelectPage } from '@src/components/account/MissingInformationModal'
import CircularProgress from '@material-ui/core/CircularProgress'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('loading')

const AccountPage = ({ location = {}, customPageContext = null }) => {
	const t = useTranslations()

	const { isMissingInformation, customerInfo, isCustomerLoggedIn } = useConnect(
		'selectIsMissingInformation',
		'selectCustomerInfo',
		'selectIsCustomerLoggedIn',
	)
	const [selectPage, setSelectPage] = useState(undefined)
	const [initCompleted, setInitCompleted] = useState(false)

	useEffect(() => {
		if (isCustomerLoggedIn !== null && isMissingInformation !== null) {
			setInitCompleted(true)
		}

		if (isCustomerLoggedIn === false) {
			setInitCompleted(true)
		}

		if (isCustomerLoggedIn === true && isMissingInformation === true) {
			setSelectPage(getSelectPage(customerInfo))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCustomerLoggedIn, isMissingInformation, selectPage])

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		if (urlParams.has('id')) {
			const id = urlParams.get('id')
			sessionStorage.setItem('id', id)
		}
		if (urlParams.has('linksource')) {
			const linksource = urlParams.get('linksource')
			sessionStorage.setItem('linksource', linksource)
		}
		if (urlParams.has('membership')) {
			const membership = urlParams.get('membership')
			sessionStorage.setItem('membership', membership)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	let komIgangContextData = usePageContextData({
		pageName: location.pathname.replace('kom-igang', 'kom-igang-custom'),
	})

	// UsePageContext hook causes some problems so we just provide a custom pageContext
	// when rendering the page from Pimcore preview.
	if (customPageContext && customPageContext.length > 0) {
		komIgangContextData = customPageContext
	}

	const selectedPage = selectPage
		? selectPage
		: location.state && location && location.state.selectedPage
		? location.state.selectedPage
		: 2

	return initCompleted ? (
		<>
			<Seo title={t('Account')} pageContextData={komIgangContextData} />
			<Account
				selectedPage={selectedPage}
				customPageContext={customPageContext}
				location={location}
			/>
		</>
	) : (
		<div {...bem('loading')}>
			<CircularProgress size='15rem' />
		</div>
	)
}

export default AccountPage
